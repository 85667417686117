export default class BiomeTiles {
    constructor(spriteSheetPath, tileWidth, tileHeight, onLoadCallback) {
        this.spriteSheet = new Image();
        this.tileWidth = tileWidth;
        this.tileHeight = tileHeight;
        this.onLoadCallback = onLoadCallback;
        this.loaded = false;
        this.tiles = {
            water: { x: 0, y: 0 },
            desert: { x: tileWidth, y: 0 },
            forest: { x: 0, y: tileHeight },
            mountain: { x: tileWidth, y: tileHeight },
        };
        this.spriteSheet.src = spriteSheetPath;

        this.spriteSheet.onload = () => {
            this.loaded = true;
            if (this.onLoadCallback) {
                this.onLoadCallback();
            }
        };
    }

    drawTile(ctx, biome, x, y, targetTileWidth, targetTileHeight, hueRotation = 0) {
        if (!this.loaded) {
            return;
        }

        if (hueRotation !== 0) {
            ctx.filter = `hue-rotate(${hueRotation}deg)`;
        }

        const tilePos = this.tiles[biome];

        const sourceX = tilePos.x;
        const sourceY = tilePos.y;
        const sourceWidth = this.tileWidth;
        const sourceHeight = this.tileHeight;

        const destX = x;
        const destY = y;
        const destWidth = targetTileWidth;
        const destHeight = targetTileHeight;

        // Calculate the scaling factors for width and height
        // const scaleX = destWidth / sourceWidth;
        // const scaleY = destHeight / sourceHeight;

        ctx.drawImage(
            this.spriteSheet,
            sourceX,
            sourceY,
            sourceWidth,
            sourceHeight,
            destX,
            destY,
            destWidth,
            destHeight
        );
    }
}
