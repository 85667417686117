import React, { useState } from 'react';
import '../styles/Login.css';

let API_URL = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    API_URL = `http://localhost:5000`
} else {
    API_URL = ``
}

function LoginComponent({ pressedButton, onLoginSuccesful }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const [isRegister, setIsRegister] = useState(pressedButton === 'login' ? false : true);

    const handleSignUp = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${API_URL}/api/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();

            if (response.ok) {
                const { user_id } = data;

                const userResponse = await fetch(`${API_URL}/users/${user_id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: user_id,
                        username: username
                    }),
                });

                const userData = await userResponse.json();
                if (userResponse.ok) {
                    onLoginSuccesful(username);
                } else {
                    console.log(`User data save failed: ${userData.message}`);
                }
            } else {
                console.log(`Signup failed: ${data.message}`);
            }
        } catch (error) {
            console.log(`Signup failed: ${error.message}`);
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        const response = await fetch(`${API_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        const data = await response.json();
        setLoginStatus(data.message); 
        if (response.ok) {
            onLoginSuccesful(username, data.caravan_data);
        } else {
            setLoginStatus(`Login failed: ${data.message}`);
        }
    };

    return (
        <div className='login-form'>
            <h2>{isRegister ? 'Register new user' : 'Login user'}</h2>
            <form onSubmit={isRegister ? handleSignUp : handleLogin}>
                <label>
                    Username:
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <br />
                <button type="submit">{isRegister ? 'Register' : 'Login'}</button>
            </form>
            <br/>
            <input type='checkbox' checked={isRegister} value={isRegister} onClick={() => setIsRegister(!isRegister)} readOnly />
            <label> new user </label>
            {loginStatus && <p>{loginStatus}</p>}
        </div>
    );
}

export default LoginComponent;
