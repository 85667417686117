export class User {
    #apiUrl;
    constructor(apiUrl, username = null, userId = null) {
        this.#apiUrl = apiUrl;
        this.userId = userId;
        this.username = username;

        this.settlements = {};
        this.inventory = [];
        this.caravans = [];
        this.explored = [];

        this.getUserInstance = this.getUserInstance.bind(this);
        this.newSettlement = this.newSettlement.bind(this);
        this.setUserData = this.setUserData.bind(this);
        this.getSettlements = this.getSettlements.bind(this);
        this.exploreTile = this.exploreTile.bind(this);
        this.addItem = this.addItem.bind(this);

        if (this.username !== null) {
            this.initializeUser();
        }
    }

    async updateOnLogin(username) {
        this.username = username;
        await this.initializUser();
    }

    async initializeUser() {
        if (this.userId === null) {
            await this.fetchUserId();
        }
        await this.fetchUserData();
    }

    async fetchUserId() {
        try {
            const response = await fetch(`${this.#apiUrl}/api/get_id/${this.username}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const userId = await response.json();
            this.userId = userId;
        } catch (error) {
            console.error('There was a problem fetching user data:', error);
        }
    }

    async fetchUserData() {
        try {
            const response = await fetch(`${this.#apiUrl}/users/${this.userId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const userData = await response.json();
            this.setUserData(userData);
        } catch (error) {
            console.error('There was a problem fetching user data:', error);
        }
    }

    async exploreTileRequest(newData) {
        try {
            const response = await fetch(`${this.#apiUrl}/users/${this.userId}/explore`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "tile": newData }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            await this.fetchUserData();
        } catch (error) {
            console.error('There was a problem fetching user data:', error);
        }
    }

    getUserInstance() {
        return this.userId;
    }

    async newSettlement() {
        await this.fetchUserData();
    }

    setUserData(userData) {
        this.username = userData.username || null;
        this.settlements = userData.settlements || {};
        this.inventory = userData.inventory || [];
        this.caravans = userData.caravans || [];
        this.explored = userData.explored_tiles || [];
    }

    getSettlements() {
        return this.settlements;
    }

    exploreTile(x, y) {
        this.exploreTileRequest({ x: x, y: y });
        this.explored.push(`${x},${y}`);
    }

    addItem(item) {
        this.inventory.push(item);
    }
}
