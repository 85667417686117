import React, { useState } from 'react';
import { useAppContext } from '../AppContext';
import { Dropdown, Modal } from 'semantic-ui-react'
import LoginComponent from "./Login";

import 'semantic-ui-css/semantic.min.css';
import '../styles/ResourceOverlay.css'

import caravanIcon from '../images/caravanSmallImg.png'
import settlementIcon from '../images/settlementR.png'
import exploreTicketIcon from '../images/ExploreTicketIcon.png'

import logo from '../images/logoCF.png'

const login_options = [
    { key: 'login', text: 'Login', name: 'login' },
    { key: 'signup', text: 'Signup', name: 'signup' },
];

const user_options = [
    { key: 'overview', text: 'Overview', name: 'overview' },
    { key: 'settlements', text: 'Settlements', name: 'settlements' },
    { key: 'inventory', text: 'Inventory', name: 'inventory' },
    { key: 'signout', text: 'Sign Out', name: 'signout' },
];

function ResourceOverlay() {
    const { application } = useAppContext();
    const inventory = [];
    const user = application.user;

    const [showInventory, setShowInventory] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [pressedButton, setPressedButton] = useState(null);

    const toggleInventory = () => {
        setShowInventory(!showInventory);
    };

    let options = [];
    if (user.userId === null) options = login_options;
    else options = user_options;

    return (
        <>
            <div className="resource-bar">
                <div className='banner-holder'>
                    <img src={logo} alt='banner' height={50} />
                </div>
                {user.userId &&
                    <>
                        <div className="resource-item">
                            <img src={caravanIcon} alt="caravan" width={32} height={32} className="resource-icon" />
                            <div className="resource-value">{user.caravans.length ?? 0}</div>
                        </div>
                        <div className="resource-item">
                            <img src={settlementIcon} alt="settlements" width={32} height={32} className="resource-icon" />
                            <div className="resource-value">{user.inventory['settlements'] ?? 0}</div>
                        </div>
                        <div className="resource-item">
                            <img src={exploreTicketIcon} alt="ExploreTickets" width={32} height={32} className="resource-icon" />
                            <div className="resource-value">{user.inventory['explore_tickets'] ?? 0}</div>
                        </div>
                    </>
                }
                <button className='inventory-button' onClick={toggleInventory}>Inventory</button>
                <Dropdown
                    text={user.userId === null ? 'Login' : user.username}
                    icon='dropdown'
                    floating
                    labeled
                    button
                    className='icon'
                >
                    <Dropdown.Menu>
                        {options.map(option => (
                            <Dropdown.Item key={option.key} {...option} onClick={() => {
                                if (login_options.some(obj => obj === option)) {
                                    setModalOpen(true);
                                    setPressedButton(option.key);
                                }
                                if (user_options.some(obj => obj === option)) {
                                    // Add
                                };
                            }} />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                <Modal open={modalOpen} onClose={() => setModalOpen(false)} style={{ width: 'auto' }}>
                    <LoginComponent pressedButton={pressedButton} onLoginSuccesful={(username, caravans) => {
                        application.setUser(username, caravans);
                        setModalOpen(false);
                    }} />
                </Modal>
            </div>
            {showInventory && (
                <div className="inventory-panel">
                    <h3 className="inventory-header">Inventory:</h3>
                    <ul className="inventory-list">
                        {inventory.map((item, index) => (
                            <li className="inventory-list-item" key={index}>
                                <img src={item.icon} alt={item.name} className="inventory-icon" />
                                {item.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}

export default ResourceOverlay;
