import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import ApplicationInstance from './classes/Application';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [application, setApplication] = useState(ApplicationInstance);
  const socket = useRef(null);
  const connected = useRef(false);

  useEffect(() => {
    ApplicationInstance.setCallback((app) => {
      socket.current = app.getSocket();
      connected.current = app.getConnected();
      setApplication(prevApp => ({ ...prevApp, ...app }));
    });

    return () => {
      ApplicationInstance.cleanup();
    }
  }, []);

  if (!socket.current) return <p>Loading...</p>;
  if (!connected.current) return <p>Connecting...</p>;

  return (
    <AppContext.Provider value={{ application, socket, connected }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
