import io from 'socket.io-client';

export default class SocketManager {
    constructor(socketUrl, updateApp, mapDataStore) {
        this.socket = io(socketUrl, {
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            randomizationFactor: 0.5
        });
        this.connected = false;

        this.updateApp = updateApp;
        this.mapDataStore = mapDataStore;

        this.initListeners();
    }

    initListeners() {
        this.socket.on('connect', () => {
            this.connected = true;
            this.updateApp(this);
        });

        this.socket.on('settlementCreated', (newSettlement) => {
            const {x, y} = newSettlement
            this.mapDataStore.mapData[`${x},${y}`].settlement = true;
        });

        this.socket.on('caravan_update', (data) => {
            this.mapDataStore.appendToCaravanList(data);
        });

        this.socket.on('caravan_completed', (data) => {
            console.log('caravan_completed', data);
            delete this.mapDataStore.caravanList[data.id];
        });
    }

    cleanup() {
        this.socket.off('connect');
        this.socket.off('settlementCreated');
        this.socket.off('initial');
        this.socket.off('caravan_update');
        this.socket.off('caravan_completed');
        this.socket.disconnect();
    }
}
