import React, { useEffect, useState, useRef } from "react";
import { useAppContext } from '../AppContext';

import BiomeTiles from '../classes/BiomeTiles';
import MapViewer from './MapViewer';

import GameHandlers from "./GameHandlers";

const GameView = () => {
    const { application, socket } = useAppContext();

    const canvasRef = useRef(null);
    const canvasCaravansRef = useRef(null);
    const infoPanel = useRef(null);

    const isDraggingRef = useRef(false);
    const startDragRef = useRef({ x: 0, y: 0 });
    const panRef = useRef({ x: 0, y: 0 });
    const zoomRef = useRef(1);
    const isPanningRef = useRef(false);

    const hoveredTileRef = useRef({ x: 0, y: 0 });

    const user = application.user;
    const mapData = useRef({});
    const caravanData = useRef({});

    const [infoPanelData, setInfoPanelData] = useState(null);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, tile: { x: 0, y: 0 } });

    const [caravanSprite, setCaravanSprite] = useState(null);
    const [settlementSprite, setSettlementSprite] = useState(null);
    const [biomeTiles, setBiomeTiles] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // Initialization
    useEffect(() => {
        const canvas = canvasRef.current;
        const caravanCanvas = canvasCaravansRef.current;

        const handleSpriteSheetLoad = () => {
            setIsLoaded(true);
        };

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            caravanCanvas.width = canvas.width;
            caravanCanvas.height = canvas.height;
        };

        const settlement = new Image();
        settlement.src = 'images/settlement2.png';
        settlement.onload = () => setSettlementSprite(settlement);

        const caravan = new Image();
        caravan.src = 'images/caravanImg.png';
        caravan.onload = () => setCaravanSprite(caravan);

        setBiomeTiles(new BiomeTiles('/images/biomesR.png', 512, 512, handleSpriteSheetLoad));

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);
        return () => {
            window.removeEventListener('resize', resizeCanvas);
        }
    }, []);

    // update and render frame
    useEffect(() => {
        const canvas = canvasRef.current;
        const caravanCanvas = canvasCaravansRef.current;
        const ctx = canvas?.getContext('2d');
        const caravanCtx = caravanCanvas?.getContext('2d');

        const tileSize = GameHandlers.baseTileSize * zoomRef.current;

        const settlementKey = `${user.settlements.x},${user.settlements.y}`;
        const userInstance = application.getUserInstance();

        let animationFrameId;

        const enforcePanningBoundaries = (dx, dy) => {
            let newX = panRef.current.x + dx;
            let newY = panRef.current.y + dy;

            const gridSizeX = GameHandlers.gridWidth * tileSize;
            const gridSizeY = GameHandlers.gridHeight * tileSize;

            const minX = -gridSizeX * zoomRef.current + window.innerWidth - GameHandlers.boundaryMargin;
            const minY = -gridSizeY * zoomRef.current + window.innerHeight - GameHandlers.boundaryMargin;
            const maxX = GameHandlers.boundaryMargin;
            const maxY = GameHandlers.boundaryMargin;

            newX = Math.max(minX, Math.min(newX, maxX));
            newY = Math.max(minY, Math.min(newY, maxY));

            panRef.current = { x: newX, y: newY };
        };

        const handleCanvasClick = (e) => {
            const tile = GameHandlers.handleCanvasClick(e, canvasRef, isPanningRef, panRef, zoomRef);

            if (tile) {
                setContextMenu({
                    visible: true,
                    x: e.clientX,
                    y: e.clientY,
                    tile: tile
                });
            }
        }
        const handleMouseDown = (e) => GameHandlers.handleMouseDown(e, isDraggingRef, startDragRef);
        const handleHover = (e) => GameHandlers.handleHover(e, canvasRef, panRef, hoveredTileRef, zoomRef);
        const handleMouseMove = (e) => {
            const dxdy = GameHandlers.handleMouseMove(e, zoomRef, isDraggingRef, startDragRef, isPanningRef);
            if (dxdy) {
                enforcePanningBoundaries(dxdy[0], dxdy[1]);
                setContextMenu({ ...contextMenu, visible: false });
            }
        }
        const handleWheel = (e) => {
            GameHandlers.handleWheel(e, zoomRef);
            setContextMenu({ ...contextMenu, visible: false });
        }
        const handleMouseUp = () => GameHandlers.handleMouseUp(isDraggingRef, isPanningRef);

        const update = () => {
            // console.log('hi');
            mapData.current = application.mapDataStore.mapData;
            caravanData.current = application.mapDataStore.caravanList;
        }

        const drawTiles = () => {
            const tileData = mapData.current;

            const startX = Math.max(0, Math.floor(-panRef.current.x / zoomRef.current / tileSize));
            const startY = Math.max(0, Math.floor(-panRef.current.y / zoomRef.current / tileSize));

            const numTilesX = Math.min(GameHandlers.gridWidth - startX, Math.ceil(canvas.width / zoomRef.current / tileSize));
            const numTilesY = Math.min(GameHandlers.gridHeight - startY, Math.ceil(canvas.height / zoomRef.current / tileSize));

            for (let i = 0; i < numTilesX || startX + i < GameHandlers.gridHeight; i++) {
                for (let j = 0; j < numTilesY || startX + j < GameHandlers.gridWidth; j++) {
                    const tileX = startX + i;
                    const tileY = startY + j;
                    const key = `${tileX},${tileY}`;
                    if (tileData[key]) {
                        const biome = tileData[key].biome;
                        biomeTiles.drawTile(ctx, biome, tileX * tileSize, tileY * tileSize, tileSize, tileSize, 0);
                        if (key === settlementKey) {
                            ctx.beginPath();
                            ctx.arc(tileX * tileSize + tileSize / 2, tileY * tileSize + tileSize / 2, tileSize / 2, 0, 2 * Math.PI);
                            ctx.fillStyle = "rgba(173, 216, 230, 0.5)";
                            ctx.fill();
                            ctx.stroke();
                        }
                        if (tileData[key].settlement) {
                            if (userInstance ? user.explored.includes(key) : true) {
                                ctx.drawImage(settlementSprite, tileX * tileSize, tileY * tileSize, tileSize, tileSize);
                            }
                        }
                        if (tileData[key].caravan && !tileData[key].settlement) {
                            if (userInstance ? user.explored.includes(key) : true) {
                                ctx.drawImage(caravanSprite, tileX * tileSize, tileY * tileSize, tileSize, tileSize);
                            }
                        }
                        if (application.getUserInstance() && !user.explored.includes(key)) {
                            ctx.fillStyle = 'rgba(20, 20, 20, 0.4)';
                            ctx.fillRect(tileX * tileSize, tileY * tileSize, tileSize, tileSize);
                        }
                    }
                }
            }
        }

        const drawCaravans = () => {
            const caravan_list = Object.entries(caravanData.current);
            caravan_list.forEach(([id, caravan]) => {
                const currentTime = Date.now();
                const progress = (currentTime - caravan.startTime) / caravan.duration;

                if (progress < 1) {
                    const currentX = caravan.start.x + (caravan.target.x - caravan.start.x) * progress;
                    const currentY = caravan.start.y + (caravan.target.y - caravan.start.y) * progress;

                    const canvasX = currentX * tileSize;
                    const canvasY = currentY * tileSize;

                    caravanCtx.drawImage(caravanSprite, canvasX, canvasY, tileSize, tileSize);
                } else {
                    mapData.current[`${caravan.target.x},${caravan.target.y}`]['caravan'] = true;
                    delete caravanData.current[id];
                }
            });
        };

        const draw = () => {
            ctx.clearRect(-panRef.current.x / zoomRef.current, -panRef.current.y / zoomRef.current, canvas.width / zoomRef.current, canvas.height / zoomRef.current);
            caravanCtx.clearRect(-panRef.current.x / zoomRef.current, -panRef.current.y / zoomRef.current, canvas.width / zoomRef.current, canvas.height / zoomRef.current);

            ctx.setTransform(zoomRef.current, 0, 0, zoomRef.current, panRef.current.x, panRef.current.y);
            caravanCtx.setTransform(zoomRef.current, 0, 0, zoomRef.current, panRef.current.x, panRef.current.y);

            ctx.fillStyle = '#3b2f2f';
            ctx.fillRect(-panRef.current.x / zoomRef.current, -panRef.current.y / zoomRef.current, canvas.width / zoomRef.current, canvas.height / zoomRef.current);

            drawTiles();

            if (
                (0 <= hoveredTileRef.current.x && hoveredTileRef.current.x < GameHandlers.gridHeight) &&
                (0 <= hoveredTileRef.current.y && hoveredTileRef.current.y < GameHandlers.gridHeight)
            ) {
                caravanCtx.strokeStyle = 'rgba(255, 0, 0, 0.5)';
                caravanCtx.lineWidth = 2;
                caravanCtx.strokeRect(hoveredTileRef.current.x * tileSize, hoveredTileRef.current.y * tileSize, tileSize, tileSize);
            }

            drawCaravans();
        };

        const gameLoop = () => {
            if (isLoaded) {
                update();
                draw();
            }
            animationFrameId = requestAnimationFrame(gameLoop);
        };

        gameLoop();

        canvas.addEventListener('click', handleCanvasClick);
        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mousemove', handleHover);
        canvas.addEventListener('wheel', handleWheel);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            cancelAnimationFrame(animationFrameId);
            canvas.removeEventListener('click', handleCanvasClick);
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mousemove', handleHover);
            canvas.removeEventListener('wheel', handleWheel);;
            window.removeEventListener('mouseup', handleMouseUp);
        }
        // eslint-disable-next-line
    }, [isLoaded])

    const exploreTile = (tile) => {
        if (mapData.current[`${tile.x},${tile.y}`].resources === undefined) {
            application.user.exploreTile(tile.x, tile.y);
            application.updateMapData();
        }
        setContextMenu({ ...contextMenu, visible: false });
    };

    const startColony = (tile) => {
        const newSettlement = {
            'x': tile.x,
            'y': tile.y,
            'userID': user.userId
        };
        socket.current.emit('newSettlement', newSettlement, (response) => {
            if (!response) return;
            user.newSettlement();
        });
        setContextMenu({ ...contextMenu, visible: false });
        application.updateApp();
    };

    const sendcaravan = (start, target) => {
        const distance = Math.sqrt(Math.pow(target.x - start.x, 2) + Math.pow(target.y - start.y, 2));
        const duration = distance * 1000 * GameHandlers.speedModifier;

        const caravanId = user.caravans[Math.floor(Math.random() * user.caravans.length)];
        if (caravanId === undefined) return;

        socket.current.emit('update_caravan', {
            id: caravanId,
            fromId: user.userId,
            start: start,
            target: target,
            startTime: Date.now(),
            duration: duration
        }, (response) => {
            if (!response) return;
            user.newSettlement();
        });
        setContextMenu({ ...contextMenu, visible: false });
    }

    const showResources = (tile) => {
        const key = `${tile.x},${tile.y}`
        const resources = mapData.current[key].resources || null;

        setInfoPanelData({ key: key, resources: resources });
        setContextMenu({ ...contextMenu, visible: false });
        infoPanel.current.style.display = 'block';
    }

    const renderContextMenu = () => {
        if (!contextMenu.visible) return;
        if (application.getUserInstance() === null) return (
            <div style={{ position: 'absolute', left: contextMenu.x, top: contextMenu.y }}>
                <div style={{ background: 'rgba(255,255,255,0.9)', borderRadius: '5px', padding: '5px' }}>
                    For tile options log into your account!
                </div>
            </div>
        );
        const key = `${contextMenu.tile.x},${contextMenu.tile.y}`
        const tile_data = mapData.current[key];

        const exploreButton = !user.explored?.includes(key);
        const resourceButton = !exploreButton;
        const colonyButton = Object.keys(user.settlements).length === 0 && tile_data.biome !== 'water' && !tile_data.settlement && user.inventory.settlements > 0;
        const sendCaravan = user.caravans.length > 0 && !tile_data.settlement && !tile_data.caravan;
        const returnCaravan = tile_data.caravan;

        return (
            <div style={{ position: 'absolute', left: contextMenu.x, top: contextMenu.y }}>
                <label>{key}</label>
                {exploreButton && <button onClick={() => exploreTile(contextMenu.tile)}>Explore</button>}
                {resourceButton && <button onClick={() => showResources(contextMenu.tile)}>Show Resources</button>}
                {colonyButton && <button onClick={() => startColony(contextMenu.tile)}>Start Colony</button>}
                {sendCaravan && <button onClick={() => sendcaravan({ ...user.settlements }, { ...contextMenu.tile })}>Send Caravan to {key}</button>}
                {returnCaravan && <button onClick={() => sendcaravan({ ...contextMenu.tile }, { ...user.settlements })}>Return Caravan</button>}
            </div>
        );
    };

    const InfoPanelData = () => {
        if (!infoPanel || !infoPanelData || !infoPanelData.resources) return null;
        return (
            <div style={{ color: 'wheat' }}>
                <h2>Tile {infoPanelData.key} has the resources:</h2>
                {infoPanelData.resources.map((res, idx) => (
                    <p key={idx}>{res}</p>
                ))}
            </div>
        );
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                <canvas
                    ref={canvasRef} width={0} height={0}
                    style={{ position: 'absolute', left: 0, top: 0, border: '1px solid #000' }}
                />
                <canvas
                    ref={canvasCaravansRef} width={0} height={0}
                    style={{ position: 'absolute', left: 0, top: 0, background: 'transparent', pointerEvents: 'none' }}
                />
                {renderContextMenu()}
            </div>
            <MapViewer />
            <div ref={infoPanel} style={{ display: 'none', position: 'absolute', right: '5px', top: '75px', minWidth: '100px', minHeight: '100px', backgroundColor: 'rgba(100,100,100,0.5)' }}>
                <button onClick={() => { setInfoPanelData(null); infoPanel.current.style.display = 'none'; }}>X</button>
                <InfoPanelData />
            </div>
        </>
    );
}

export default GameView;