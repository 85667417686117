export default class MapDataStore {
  #apiUrl;
  constructor(apiUrl) {
    this.#apiUrl = apiUrl;

    this.gridWidth = 50;
    this.gridHeight = 50;

    this.mapData = null;
    this.exploredTiles = [];

    this.caravanList = {};
  }

  async fetchMapData(startRow, endRow) {
    try {
      const response = await fetch(`${this.#apiUrl}/get_map?start_row=${startRow}&end_row=${endRow}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      this.updateMapData(data)
    } catch (error) {
      throw error;
    }
  }

  async fetchTileDetails(keys) {
    try {
      const response = await fetch(`${this.#apiUrl}/get_tile_details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tile_list: keys }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  appendToCaravanList(newCaravan) {
    this.caravanList[newCaravan.id] = newCaravan.caravan;
  }

  updateMapData(newData) {
    this.mapData = { ...this.mapData, ...newData };
  }

  updateMapEntry(key, newData) {
    this.mapData = { ...this.mapData, [key]: newData };
  }

  async updateExploredTiles() {
    const data = await this.fetchTileDetails(this.exploredTiles);
    data.forEach((item) => {
      const key = `${item.x},${item.y}`;
      this.updateMapEntry(key, item);
    })
  }

  randomMapData() {
    const biomes = ['forest', 'desert', 'water', 'mountain'];
    let randomMapData = {};

    for (let x = 0; x < this.gridWidth; x++) {
      for (let y = 0; y < this.gridHeight; y++) {
        randomMapData[`${x},${y}`] = {
          biome: biomes[Math.floor(Math.random() * biomes.length)],
          settlement: false,
          caravan: false
        }
        if (Math.random() <= 0.02) {
          randomMapData[`${x},${y}`].settlement = true;
        }
        if (Math.random() <= 0.002) {
          if (Math.random() < 0.5) {
            randomMapData[`${x},${y}`].caravan = true;
          }
          else {
            const startPos = findSettlementInAreaOrRandom(randomMapData, x, y) || null;
            if (!startPos) continue;

            const distance = Math.sqrt(Math.pow(x - startPos.x, 2) + Math.pow(y - startPos.y, 2));
            const duration = distance * 1000 * (Math.random() * 5 + 0.5);

            const newCaravanId = Object.keys(this.caravanList).length + 1;
            this.caravanList[newCaravanId] = {
              id: newCaravanId,
              fromId: null,
              start: { ...startPos },
              target: { x: x, y: y },
              startTime: Date.now(),
              duration: duration
            }
          }
        }
      }
    }
    this.updateMapData(randomMapData);
  }

  getMapData() {
    return this.mapData;
  }
}

function findSettlementInAreaOrRandom(map, targetX, targetY) {
  let settlementsInArea = [];
  let radius = 2;

  for (let x = targetX - radius; x <= targetX + radius; x++) {
      for (let y = targetY - radius; y <= targetY + radius; y++) {
          let key = `${x},${y}`;
          if (map[key] && map[key].settlement) {
              settlementsInArea.push({ x, y });
          }
      }
  }

  if (settlementsInArea.length > 0) {
      let randomIndex = Math.floor(Math.random() * settlementsInArea.length);
      return settlementsInArea[randomIndex];
  } else {
      let allSettlements = [];
      for (let key in map) {
          if (map[key].settlement) {
              let [x, y] = key.split(',').map(Number);
              allSettlements.push({ x, y });
          }
      }

      if (allSettlements.length === 0) return null; // No settlements found in the entire grid

      let randomIndex = Math.floor(Math.random() * allSettlements.length);
      return allSettlements[randomIndex];
  }
}
