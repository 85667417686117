import React from 'react';

import GameView from './components/GameView';
import ResourceOverlay from './components/ResourceOverlay';
import './App.css';

function App() {
  return (
    <div className="App">
      <GameView />
      <ResourceOverlay />
    </div>
  );
}

export default App;
