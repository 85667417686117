import { User } from "./User";
import MapDataStore from "./MapDataStore"
import SocketManager from "./SocketManager";

class Application {
  #apiUrl;
  constructor(apiUrl, callback = null) {
    this.#apiUrl = apiUrl;
    this.updateApp = this.updateApp.bind(this);

    
    this.updateAppCallback = callback;
    
    this.user = new User(this.#apiUrl);
    this.mapDataStore = new MapDataStore(this.#apiUrl);
    this.socketManager = new SocketManager(this.#apiUrl, this.updateApp, this.mapDataStore);


    this.cleanup = this.cleanup.bind(this);
    this.getConnected = this.getConnected.bind(this);
    this.setUser = this.setUser.bind(this);
    this.getSocket = this.getSocket.bind(this);
    this.updateMapData = this.updateMapData.bind(this);
    this.randomMapData = this.randomMapData.bind(this);
    this.getUserInstance = this.getUserInstance.bind(this);
  }

  cleanup() {
    console.log('bye!');
    this.socketManager.cleanup();
  }

  getConnected() {
    return this.socketManager.connected;
  }

  getSocket() {
    return this.socketManager.socket;
  }

  getApiUrl() {
    return this.#apiUrl;
  }

  getUserInstance() {
    return this.user.getUserInstance();
  }

  setCallback(callback) {
    this.updateAppCallback = callback;
  }

  updateApp() {
    if (this.updateAppCallback) {
      this.updateAppCallback(this);
    }
  }

  async setUser(username, caravans = {}) {
    this.user.username = username;
    await this.user.initializeUser();

    this.mapDataStore.caravanList = Object.values(caravans);
    this.updateApp();
  }

  async updateMapData() {
    if (this.mapDataStore.exploredTiles !== this.user.explored) {
      this.mapDataStore.exploredTiles = this.user.explored;
      await this.mapDataStore.updateExploredTiles();
      this.updateApp();
    }
  }

  randomMapData() {
    this.mapDataStore.randomMapData();
    this.updateApp();
  }
}

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '';
const ApplicationInstance = new Application(apiUrl);

export default ApplicationInstance;
