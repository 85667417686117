import React, { useState, useEffect } from 'react';
import { useAppContext } from '../AppContext';

const MapViewer = () => {
  const { application } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);

  const chunkSize = 10;
  const maxPosition = 50;

  useEffect(() => {
    const loadData = async () => {
      if (currentPosition >= maxPosition) {
        application.updateMapData();
        return;
      }
      try {
        setLoading(true);
        const startRow = currentPosition;
        const endRow = Math.min(currentPosition + chunkSize, maxPosition);
        await application.mapDataStore.fetchMapData(startRow, endRow);
        setCurrentPosition(prevPosition => Math.min(prevPosition + chunkSize, maxPosition));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching map data:', error);
      }
    };

    if (application.getUserInstance()) {
      loadData();
    } else if(!application.mapDataStore.getMapData()) {
      application.randomMapData();
    }
  }, [currentPosition, application]);

  return (
    <div>
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default MapViewer;