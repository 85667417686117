const gridWidth = 50;
const gridHeight = 50;
const baseTileSize = 30;
const boundaryMargin = 100;
const speedModifier = 5;
const dragThreshold = 2;

const GameHandlers = {
    gridWidth: gridWidth,
    gridHeight: gridHeight,
    baseTileSize: baseTileSize,
    boundaryMargin: boundaryMargin,
    speedModifier: speedModifier,
    dragThreshold: dragThreshold,

    handleMouseDown: (e, isDraggingRef, startDragRef) => {
        isDraggingRef.current = true;
        startDragRef.current = { x: e.clientX, y: e.clientY };
    },
    handleHover: (e, canvasRef, panRef, hoveredTileRef, zoomRef) => {
        const rect = canvasRef.current.getBoundingClientRect();

        let canvasX = (e.clientX - rect.top - panRef.current.x) / zoomRef.current;
        let canvasY = (e.clientY - rect.left - panRef.current.y) / zoomRef.current;
    
        const tileX = Math.floor(canvasX / baseTileSize);
        const tileY = Math.floor(canvasY / baseTileSize);
    
        hoveredTileRef.current = { x: tileX, y: tileY };
    },
    
    handleMouseMove: (e, zoomRef, isDraggingRef, startDragRef, isPanningRef) => {
        if (!isDraggingRef.current) return null;

        let dx = (e.clientX - startDragRef.current.x) / zoomRef.current;
        let dy = (e.clientY - startDragRef.current.y) / zoomRef.current;

        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance <= dragThreshold) return null;

        startDragRef.current = { x: e.clientX, y: e.clientY };
        isPanningRef.current = true;

        return [dx, dy]
    },
    handleMouseUp: (isDraggingRef, isPanningRef) => {
        isDraggingRef.current = false;
        setTimeout(() => {
            isPanningRef.current = false;
        }, 1000);
    },
    handleCanvasClick: (e, canvasRef, isPanningRef, panRef, zoomRef) => {
        if (isPanningRef.current) return null;
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();

        let canvasX = e.clientX - rect.left;
        let canvasY = e.clientY - rect.top;

        let gridX = (canvasX - panRef.current.x / zoomRef.current) / zoomRef.current;
        let gridY = (canvasY - panRef.current.y / zoomRef.current) / zoomRef.current;

        const tileX = Math.floor(gridX / (baseTileSize * zoomRef.current));
        const tileY = Math.floor(gridY / (baseTileSize * zoomRef.current));

        return { x: tileX, y: tileY }
    },
    handleWheel: (e, zoomRef) => {
        const zoomFactor = 0.1;
        const newZoom = Math.min(Math.max(zoomRef.current * (1 - zoomFactor * Math.sign(e.deltaY)), 1), 3);

        zoomRef.current = newZoom;
    }
};

export default GameHandlers;
